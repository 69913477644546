import { type SanityModule } from '@data/sanity/queries/types/modules'

import Module from './module'

export interface ModulesProps {
  modules: SanityModule[] | null
}

const Modules = ({ modules }: ModulesProps) => {
  if (!modules) {
    return null
  }

  return (
    <>
      {modules.map((module) => (
        <Module key={module._key} module={module} />
      ))}
    </>
  )
}

export default Modules
