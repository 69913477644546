import groq from 'groq'

import { linkPageFragment } from '@data/sanity/queries/link'

export const frameBuilderStandardSizeObjectFragment = groq`
  "standardSizes": *[
    _type == "frameBuilderStandardSize" &&
    locale == $locale
  ]
    | order(order asc)
  {
    ...,
    link {
      ...,
      page->{
        ${linkPageFragment}
      },
    },
  },
`
