import dynamic from 'next/dynamic'
import { useContext } from 'react'

import { type SanityModule } from '@data/sanity/queries/types/modules'
import { CartContext } from '@lib/cart/context'
import { LanguageContext } from '@lib/language-context'
import { ShopContext } from '@lib/shop-context'

const BlogPostBody = dynamic(() => import('./blog/blog-post-body'))
const BlogPostGrid = dynamic(() => import('./blog/blog-post-grid'))
const BlogPostHeader = dynamic(() => import('./blog/blog-post-header'))
const BlogPostItem = dynamic(() => import('./blog/blog-post-item'))
const BlogPostReadMore = dynamic(() => import('./blog/blog-post-read-more'))
const CartForm = dynamic(() => import('./shop/cart/form'))
const Collection = dynamic(() => import('./shop/collection'))
const ContentCarousel = dynamic(() => import('../blocks/content-carousel'))
const CTA = dynamic(() => import('../blocks/cta'))
const DividerPhoto = dynamic(() => import('./divider-photo'))
const Grid = dynamic(() => import('./grid'))
const FrameBuilder = dynamic(() => import('@frame-builder/module'))
const Hero = dynamic(() => import('./hero'))
const ImageAndText = dynamic(() => import('./image-and-text'))
const LogoGrid = dynamic(() => import('./logo-grid'))
const Marquee = dynamic(() => import('./marquee'))
const PricingPlans = dynamic(() => import('./pricing-plans'))
const ProductHero = dynamic(() => import('./shop/product-hero'))
const TeamMembersGrid = dynamic(() => import('./team-members-grid'))
const VideoModule = dynamic(() => import('./video'))

interface ModuleProps {
  module: SanityModule
}

const Module = ({ module }: ModuleProps) => {
  const { locale } = useContext(LanguageContext)
  const { currency, taxRate } = useContext(ShopContext)
  const { isCartProductAdding, addItemsToCart } = useContext(CartContext)

  switch (module._type) {
    case 'blogAuthorPostGrid': {
      const { posts, options } = module

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogCategoryPostGrid': {
      const { posts, options } = module

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogPostBody': {
      const { content } = module

      return <BlogPostBody content={content} />
    }

    case 'blogPostGrid': {
      const { posts, options } = module

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogPostHeader': {
      const { post, options } = module

      if (!post) {
        return null
      }

      return <BlogPostHeader post={post} options={options} />
    }

    case 'blogPostItem': {
      const { post, options, background, spacing } = module

      return (
        <BlogPostItem
          post={post}
          options={options}
          background={background}
          spacing={spacing}
        />
      )
    }

    case 'blogPostReadMore': {
      const { posts } = module

      return <BlogPostReadMore posts={posts} />
    }

    case 'cartForm': {
      const { active, cartSettings } = module

      if (!active || !cartSettings) {
        return null
      }

      return <CartForm cartSettings={cartSettings} />
    }

    case 'collectionGrid': {
      const {
        active,
        collection,
        productCategory,
        shop,
        cartSettings,
        collectionStrings,
      } = module

      if (!active) {
        return null
      }

      if (shop?.products && shop.products.length > 0) {
        return (
          <Collection
            collectionStrings={collectionStrings}
            products={shop.products}
            featuredProductIds={shop.featuredProductIds ?? []}
            paginationLimit={cartSettings?.paginationLimit ?? 10}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
          />
        )
      }

      if (collection?.products && collection.products.length > 0) {
        return (
          <Collection
            collectionStrings={collectionStrings}
            products={collection.products}
            productIds={collection.productIds}
            featuredProductIds={collection.featuredProductIds ?? []}
            paginationLimit={cartSettings?.paginationLimit ?? 10}
            title={collection.title}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
          />
        )
      }

      if (productCategory?.products && productCategory.products.length > 0) {
        return (
          <Collection
            collectionStrings={collectionStrings}
            products={productCategory.products}
            featuredProductIds={[]}
            paginationLimit={cartSettings?.paginationLimit ?? 10}
            title={productCategory.title}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
          />
        )
      }

      return null
    }

    case 'contentCarousel': {
      const { title, items } = module

      return <ContentCarousel title={title} items={items} hasContainer />
    }

    case 'cta': {
      const { content, image } = module

      return (
        <section className="md:container md:py-10">
          <CTA content={content} image={image} />
        </section>
      )
    }

    case 'dividerPhoto': {
      const { photo } = module

      return <DividerPhoto photo={photo} />
    }

    case 'grid': {
      const {
        columns,
        size,
        spacing,
        background,
        noColumnGaps,
        noRowGaps,
        reverseSequence,
      } = module

      return (
        <Grid
          columns={columns}
          size={size}
          spacing={spacing}
          background={background}
          noColumnGaps={noColumnGaps}
          noRowGaps={noRowGaps}
          reverseSequence={reverseSequence}
        />
      )
    }

    case 'frameBuilder': {
      const {
        updateHash,
        showProgressBar,
        strings,
        defaultFrame,
        servicePrices,
        standardSizes,
        profiles,
        textures,
        passepartoutSizes,
        passepartoutColors,
        glass,
        spacerSizes,
        spacerColors,
      } = module

      return (
        <FrameBuilder
          strings={strings}
          defaultFrame={defaultFrame}
          servicePrices={servicePrices}
          standardSizes={standardSizes}
          profiles={profiles}
          textures={textures}
          passepartoutSizes={passepartoutSizes}
          passepartoutColors={passepartoutColors}
          glass={glass}
          spacerSizes={spacerSizes}
          spacerColors={spacerColors}
          showProgressBar={!!showProgressBar}
          updateHash={!!updateHash}
          locale={locale}
          currency={currency}
          taxRate={taxRate}
          isCartProductAdding={isCartProductAdding}
          addItemsToCart={addItemsToCart}
        />
      )
    }

    case 'hero': {
      const { bgType, content, photos, lottieAssetUrl, muxVideo } = module

      return (
        <Hero
          bgType={bgType}
          content={content}
          photos={photos}
          lottieAssetUrl={lottieAssetUrl}
          muxVideo={muxVideo}
          overlay
        />
      )
    }

    case 'imageAndText': {
      const { image, textContent, textPosition, background } = module

      return (
        <ImageAndText
          image={image}
          textContent={textContent}
          textPosition={textPosition}
          background={background}
        />
      )
    }

    case 'logoGrid': {
      const { title, logos } = module

      return <LogoGrid title={title} logos={logos} />
    }

    case 'marquee': {
      const { content, contentAlignment, items, speed, reverse, pausable } =
        module

      return (
        <Marquee
          content={content}
          contentAlignment={contentAlignment}
          items={items}
          speed={speed ?? 0.5}
          reverse={reverse}
          pausable={pausable}
        />
      )
    }

    case 'predefinedPageModule': {
      const { pageModules } = module

      return (
        <>
          {pageModules?.map((pageModule) => (
            <Module key={pageModule._key} module={pageModule} />
          ))}
        </>
      )
    }

    case 'pricingPlans': {
      const { plans } = module

      return <PricingPlans plans={plans} />
    }

    case 'productHero': {
      const { active, product } = module

      if (!active || !product) {
        return null
      }

      return <ProductHero product={product} />
    }

    case 'teamMembersGrid': {
      const { title, subtitle, teamMembers } = module

      return (
        <TeamMembersGrid
          title={title}
          subtitle={subtitle}
          teamMembers={teamMembers}
        />
      )
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio, borderRadius } = module

      return (
        <VideoModule
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )
    }
  }
}

export default Module
